import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import  {createRole, deleteRole, getAllroles, updateRole}  from "../Api/role";
import { RoleState } from "../../InterFace/InterFace";

// 

const initialState:RoleState = {
  roles: [],
  role: {},
  loading: false,
  error: null,
};





const AsyncFunctionThunk = (name: string, apiFunction: any) => {
  return createAsyncThunk<any, any>(
    `mode/${name}`,
    async (data: any) => {
      try {
        const response: AxiosResponse<any, any> = await apiFunction(data);
        return response.data;
      } catch (error) {
        console.error(`Failed to ${name}:`, error);
        throw error;
      }
    }
  );
};
 
export const fetchRoles = AsyncFunctionThunk('fetchRoles', getAllroles);
export const createRoles = AsyncFunctionThunk('createRoles', createRole);
export const deleteRoles = AsyncFunctionThunk('deleteRoles', deleteRole);
export const updateRoles = AsyncFunctionThunk('updateRoles', updateRole);





 

const roleSlice = createSlice({
  name: 'roleSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // fetchRoles
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
        state.loading = false;
      })
      .addCase(fetchRoles.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loading = false;
      })

      // createRoles
      .addCase(deleteRoles.fulfilled, (state, action) => {
        state.role = action.payload;
        state.loading = false;
      })
      .addCase(deleteRoles.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteRoles.rejected, (state, action) => {
        state.loading = false;
        
      })
    
      // deleteRoles
      .addCase(createRoles.fulfilled, (state, action) => {
        state.role = action.payload;
        state.loading = false;
      })
      .addCase(createRoles.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createRoles.rejected, (state, action) => {
        state.loading = false;
        
      })

      // updateRoles
       .addCase(updateRoles.fulfilled, (state, action) => {
        state.role = action.payload;
        state.loading = false;
      })
      .addCase(updateRoles.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateRoles.rejected, (state, action) => {
        state.loading = false;
        
      })
  },
});

export default roleSlice.reducer;
