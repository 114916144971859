import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import  {allModeWithAdmin, createMode, deleteMode, getAllmodes,getbtIDmodes, updateMode}  from "../Api/Modes";

// 
 
 
 
const initialState = {
  modes: [],
  mode: {},
  modesWithAdmin:[],
  loading: false,
  error: null,
};



const AsyncFunctionThunk = (name: string, apiFunction: any) => {
  return createAsyncThunk<any, any>(
    `mode/${name}`,
    async (data: any) => {
      try {
        const response: AxiosResponse<any, any> = await apiFunction(data);
        return response.data;
      } catch (error) {
        console.error(`Failed to ${name}:`, error);
        throw error;
      }
    }
  );
};
 
export const fetchModes = AsyncFunctionThunk('fetchUser', getAllmodes);
export const getbtIDmode = AsyncFunctionThunk('getbtIDmodes', getbtIDmodes);
export const createModes = AsyncFunctionThunk('createModes', createMode);
export const deleteModes = AsyncFunctionThunk('deleteModes', deleteMode);
export const updateModes = AsyncFunctionThunk('updateModes', updateMode);
export const allModeWithAdmins = AsyncFunctionThunk('allModeWithAdmins', allModeWithAdmin);


 
const modeSlice = createSlice({
  name: 'modeSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModes.fulfilled, (state, action) => {
        
        state.modes = action.payload;

        // state.users.push(...action.payload);
        state.loading = false;
      })
      .addCase(fetchModes.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchModes.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getbtIDmode.fulfilled, (state, action) => {
        console.log(action.payload)
        state.mode = action.payload;

        // state.users.push(...action.payload);
        state.loading = false;
      })
      .addCase(getbtIDmode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getbtIDmode.rejected, (state, action) => {
        state.loading = false;
      })
    

      
      // createModes
      .addCase(deleteModes.fulfilled, (state, action) => {
        state.mode = action.payload;
        state.loading = false;
      })
      .addCase(deleteModes.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteModes.rejected, (state, action) => {
        state.loading = false;
        
      })
    
      // deleteModes
      .addCase(createModes.fulfilled, (state, action) => {
        state.mode = action.payload;
        state.loading = false;
      })
      .addCase(createModes.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createModes.rejected, (state, action) => {
        state.loading = false;
        
      })

      // updateModes
       .addCase(updateModes.fulfilled, (state, action) => {
        state.mode = action.payload;
        state.loading = false;
      })
      .addCase(updateModes.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateModes.rejected, (state, action) => {
        state.loading = false;
        
      })

       // allModeWithAdmins
       .addCase(allModeWithAdmins.fulfilled, (state, action) => {
        state.modesWithAdmin = action.payload;
        state.loading = false;
      })
      .addCase(allModeWithAdmins.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(allModeWithAdmins.rejected, (state, action) => {
        state.loading = false;
        
      })
  },
});

export default modeSlice.reducer;
